<template>
  <div class="common-bg page-container">
    <van-nav-bar :title="title" left-arrow @click-left="onClickLeft" />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-cell title="当前余额" :value="totalAmount" />
        <van-field
          v-model="form.mobile"
          label="收款手机号"
          placeholder="收款手机号"
          :rules="[{ required: true, message: '请输入收款手机号' }]"
        />
        <van-field
          v-model="form.num"
          label="转账金额"
          placeholder="转账金额"
          :rules="[{ required: true, message: '请输入转账金额' }]"
        />
      </van-cell-group>
      <div style="margin: 16px">
        <van-button round block type="primary" native-type="submit">
          确认转账
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { balanceTransfer } from '@/utils/api.js'
export default {
  data() {
    return {
      title: '余额互转',
      form: {},
      totalAmount: '0'
    }
  },
  created() {
    let totalAmount = this.$route.query.totalAmount
    if (totalAmount) {
      this.totalAmount = totalAmount
    }
  },
  methods: {
    onSubmit() {
      let { num, mobile } = this.form
      let form = {
        amount: Number(num),
        toMobile: mobile
      }
      balanceTransfer(form).then((res) => {
        this.$toast(res.message)
        this.onClickLeft()
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  ::v-deep .van-field__control {
    text-align: right;
  }
}
</style>
